.error-page{
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  &__content{
    display: flex;
    max-width: 1070px;
    margin: 0 auto;
  }
  &-text{
    margin: auto 0px;
    &__content{
      margin-top: 12px;
      margin-bottom: 35px;
      max-width: 410px;
    }
    &__h1{
      margin-bottom: 0px;
      margin-top: 0px;
      font-weight: 600;
      font-size: 42px;
      line-height: 50px;
      color: #333333;
    }
  }
}
@media (max-width: 768px) {
  .error-page__content__img{
    display: none;
  }
  .error-page-text__h1{
    font-size: 30px;
  }
}
